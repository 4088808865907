
import { defineComponent,ref, onMounted } from "vue";
import KTWidget6 from "@/components/widgets/feeds/Widget6.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {ElLoading} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: "my-orders",
  components: {
    KTDatatable,
  },

data() {
  const route = useRoute()
  const router = useRouter()
  const { t } = useI18n();
  const isUserAuthenticated = store.getters.isUserAuthenticated
  if(isUserAuthenticated == false) {
    router.push({ name: "dashboard" });
  }
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(255, 255, 255, 0.7)',
  })




  const headerConfig = ref([
    {
      name: "Id",
      key: "id",
      sortable: true,
    },

    {
      name: t('event_name'),
      key: "event_name",
      sortable: true,
    },
    {
      name: t('billing'),
      key: "billing",
      sortable: true,
    },
    {
      name: t('seats_count'),
      key: "seats_count",
      sortable: true,
    },
    {
      name: t('entrance_count'),
      key: "entrance_count",
      sortable: true,
    },
    {
      name: t('date'),
      key: "date",
      sortable: true,
    },
    {
      name: t('tickets'),
      key: "tickets",
      sortable: true,
    },
    {
      name: t('status'),
      key: "status",
      sortable: true,
    },

    // {
    //   name: "Actions",
    //   key: "actions",
    // },
  ]);
  const orders = {};

  return {
    loading,
   orders,
    headerConfig
  }
},
  methods: {
    userOrders() {
       store.dispatch(Actions.GET_USER_ORDERS).then(response => {
        this.loading.close();
        this.orders=response.data;
        console.log(this.orders,'this.ordersthis.orders')
        // this.dataItems = response.data
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    }
  },
 async created() {
    await this.userOrders();
  },

});
